import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { RouteResolverService } from '@skykick/core';
import { AbstractUserProvider } from '@skykick/platform-identity-auth-auth0-angular';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AccountGuard implements CanActivate {
  constructor(
    private userProvider: AbstractUserProvider,
    private router: Router,
    private routeResolverService: RouteResolverService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    var user = this.userProvider.getCurrentUser();
    //We don't want to use a magic string here. Need to find a place where all permissions are stored.
    if (!user.hasPermission('PartnerPortalAdmin')) {
      this.document.location.href = `${this.routeResolverService.generateRatRoute(
        'core' //TODO - Add this to the AppId enum.
      )}error`;
      return false;
    }

    return true;
  }
}
