import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountComponent } from './account/account.component';
import { AccountGuard } from './account.guard';

export const ACCOUNT_ROUTES: Routes = [
    {
        path: 'account',
        component: AccountComponent,
        canActivate: [AccountGuard]
    }
];

@NgModule({
  imports: [RouterModule.forChild(ACCOUNT_ROUTES)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
